import store from "../../index"
import { getModule,Module,VuexModule,Mutation } from "vuex-module-decorators"
import { ShopItemType,ShopMyCouponType } from "@/Type/index"
import { PutOrderUpData } from "@/Type/StoreType"

export interface ShopSucType<T=string>{
    ShopSuc:ShopItemType; // 店铺详情
    OnShopList:Array<any>
    AppointShop:boolean;
    PutOrder:PutOrderUpData
    PutCouponList:Array<ShopMyCouponType>

    SetShopSuc(data:ShopItemType):void
    SetOnShopList(data:Array<any>):void;
    SetAppointShop(data:boolean):void;
    SetPutOrder( data:PutOrderUpData ):void;
}

@Module({
    name:"ShopSuc",
    store,
    dynamic:true
})
export default class ShopSuc extends VuexModule implements ShopSucType{
    ShopSuc:ShopItemType = {}
    OnShopList:Array<any> = []
    AppointShop = false
    PutOrder:PutOrderUpData = {
        TimeList:[],
        TimeIndex:0,
        AskList:[],
        AskIndex:0,
        StateIndex:0,
        CouponData:{},
        Message:""
    }
    PutCouponList:Array<ShopMyCouponType> = []

    // 店铺详情
    get GetShopSuc(){
        return this.ShopSuc
    }
    @Mutation
    SetShopSuc(data:ShopItemType){
        this.ShopSuc = data
    }
    // 选中的商品
    get GetOnShopList(){
        return this.OnShopList
    }
    @Mutation
    SetOnShopList(data:Array<any>){
        this.OnShopList = data
    }
    // 是否自约取餐
    get GetAppointShop(){
        return this.AppointShop
    }
    @Mutation
    SetAppointShop(data:boolean){
        this.AppointShop = data
    }
    // 提交订单 缓存数据
    get GetPutOrder(){
        return this.PutOrder
    }
    @Mutation
    SetPutOrder( data:PutOrderUpData ){
        this.PutOrder = data
    }
    // 提交订单数据缓存
    get GetPutCouponList(){
        return this.PutCouponList
    }
    @Mutation
    SetPutCouponList(data:ShopMyCouponType[]){
        this.PutCouponList = data
    }
}

export const ShopSucStore = getModule( ShopSuc )