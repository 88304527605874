
import Axios from "../Config/shop"
import {
    AllUpDataType,
    PayUpDataType,
    MyOrderListType,
    ChangeOrderState, RecommendProductType, GetShopFeedbackListType, WxJsSdkConfigType,
} from "./interfaceType"

interface HeadType<T=string>{
    userId:T|number;
    token:T;
}

interface BodyType<T=number>{
    pageNo:T;
    pageSize:T;
}

// 广场
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function AllShop( headers:HeadType,body:AllUpDataType ):Promise<any>{
    return (
        Axios({
            url:"/app/store/storeList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 店铺详情
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GetShopSuc( headers:HeadType,body:{ id:number|string } ):Promise<any>{
    return (
        Axios({
            url:"/app/store/storeDetail",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 店铺分类
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GetShopTypeList( headers:HeadType,body:{ storeId:number|string } ):Promise<any>{
    return (
        Axios({
            url:"/app/store/storeCatalog",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求商品
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GetShopComment( headers:HeadType,body:{ catalogId:number|string } ):Promise<any>{
    return (
        Axios({
            url:"/app/store/productList",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 创建订单
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function CreateOrder( headers:HeadType,body:PayUpDataType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/createOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 请求支付参数
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PayBefore( headers:HeadType,body:{ orderId:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/weixinApiPay",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 支付后回调
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function PayAfter( headers:HeadType,body:{ orderId:string|number } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/callBack",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 我的订单列表
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MyOrderList ( headers:HeadType,body:MyOrderListType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/myOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 订单详情
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function MyOrderSuc( headers:HeadType,body:{ orderId:string,pageNo?:number,pageSize?:number } ):Promise<any>{
    return (
        Axios({
            url:"/app/shoppingOrder/queryOrderDetail",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 修改订单信息状态
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function BackOrder( headers:HeadType,body:ChangeOrderState ):Promise<any>{
    return (
        Axios({
            url:"/app/order/updateOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 退款
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function BackPay( headers:HeadType,body:{ orderId:string } ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/refund",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 商品推荐
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RecommendProduct( headers:HeadType,body:RecommendProductType ):Promise<any>{
    return (
        Axios({
            url:"/app/store/queryShopRecommend",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 查询异常订单
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GetShopFeedbackList( headers:HeadType,body:GetShopFeedbackListType ):Promise<any>{
    return (
        Axios({
            url:"/app/order/queryFuzzyOrder",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 初始化
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function WxJsSdkConfig( headers:HeadType,body:WxJsSdkConfigType ):Promise<any>{
    return (
        Axios({
            url:"/app/weixin/weixinApiConfig",
            method:"POST",
            headers,
            data:body
        })
    )
}

/**
 *  店铺优惠卷
*/
interface FindCouponType<T=string> extends BodyType<number>{
    storeId:T|number;
}
// 店铺查询
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function FindCoupon( headers:HeadType,body:FindCouponType ):Promise<any>{
    return (
        Axios({
            url:"/app/coupon/queryCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 领取店铺优惠卷
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GetShopCoupon( headers:HeadType,body:{ couponId:string|number; } ):Promise<any>{
    return (
        Axios({
            url:"/app/coupon/receiveShopCoupon",
            method:"POST",
            headers,
            data:body
        })
    )
}

// 领取店铺优惠卷列表
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function GetShopCouponList( headers:HeadType,body:{ storeId:string|number;status:number; } ):Promise<any>{
    return (
        Axios({
            url:"/app/coupon/userShopCouponList",
            method:"POST",
            headers,
            data:body
        })
    )
}
// 支付宝支付
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function aliPayh5(headers:HeadType,body:{ phone :string|number;msg:string }):Promise<any>{
    return (
        Axios({
            url:"/app/ali/aliPay",
            method:"POST",
            headers,
            data:body
        })
    )
}

